import { createRouter, createWebHashHistory } from 'vue-router'
import common from './modules/common'
import product from './modules/product'
import { RouteType, useCommonStore } from '@/stores/modules/common'
import { getAllRouteInfos } from '@/components/common/Utils/commonUtil'

const routes = [...common, ...product]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const commonStore = useCommonStore()
  const findItem = getAllRouteInfos().find((item) => item.path === to.path)
  if (findItem) {
    commonStore.curRouteType = findItem.type
  }
  if (to.path.startsWith('/product')) {
    commonStore.curRouteType = RouteType.product
  }
  next()
})

export default router
