<script setup lang="ts">
import { ref } from 'vue'
import { useCommonStore } from '@/stores/modules/common'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperObj } from 'swiper/types'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'
import { getAssetsFile } from '@/utils/common'

const commonStore = useCommonStore()
const swiperRef = ref<SwiperObj>()

const onSwiper = (swiper: SwiperObj) => {
  swiperRef.value = swiper
}
</script>

<template>
  <div class="home-wrapper">
    <template v-for="item in commonStore.apps" :key="item.product">
      <div class="product-wrapper" :class="{ developing: item.state !== 'complete' }">
        <template v-if="item.state === 'complete'">
          <div class="product-intro">
            <h2 class="product-title">{{ item.name }}</h2>
            <div class="product-desc">{{ item.desc }}</div>
            <div class="product-content">{{ item.content }}</div>
          </div>
          <swiper
            class="product-images swiper-container"
            :modules="[Autoplay]"
            :autoplay="true"
            @swiper="onSwiper"
          >
            <swiper-slide
              v-for="(intro, introIndex) in item.intros"
              :key="introIndex"
              class="swiper-item"
            >
              <img class="img-item" :src="`${getAssetsFile(intro.image)}`" alt="" />
            </swiper-slide>
          </swiper>
        </template>
        <template v-else>
          <div class="product-intro">
            <h2 class="product-title">{{ item.name }}</h2>
            <div class="product-desc">{{ item.state_desc }}</div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg-light-gray);
}

.product-wrapper {
  //display: flex;
  margin: 15px;
  width: 100%;
  height: 800px;
  background-color: var(--bg-white);
  border-radius: 5px;
  display: flex;
}

.product-intro {
  flex: 1;
  height: 100%;

  .product-title {
    margin: 50px 30px 0;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  .product-desc {
    margin: 30px 30px 0;
    font-size: 18px;
    text-indent: 40px;
    line-height: 2;
  }

  .product-content {
    margin: 30px 30px 0;
    font-size: 18px;
    text-indent: 40px;
    line-height: 2;
  }
}

.product-wrapper.developing {
  height: 200px;
}

.product-images {
  width: 400px;
  height: 100%;
  border: 1px solid var(--color-light-gray);

  .swiper-wrapper {
    height: 100%;

    .swiper-item {
      height: 100%;

      .img-item {
        height: 100%;
      }
    }
  }
}
</style>
