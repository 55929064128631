<script setup lang="ts"></script>

<template>
  <div class="about-wrapper">
    <h3 class="about-title">合肥苜蓿科技有限公司</h3>
    <span class="about-content">
      <p>
        合肥苜蓿科技有限公司是一家专注于软件开发自营、软件技术服务、技术咨询等业务的科技型公司。
      </p>
      <p>
        其中软件开发自营是公司主营业务，“苜蓿小站”是公司主推的一款软件，是一款集成日常快捷工具使用的软件。
      </p>
      <p>另外，“苜蓿百宝箱”也在开发之中，敬请期待……</p>
    </span>
  </div>
</template>

<style scoped lang="scss">
.about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
}

.about-title {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  font-size: 20px;
  text-align: left;
  line-height: 60px;
  font-weight: 600;
}

.about-content {
  padding: 10px;
  margin: 20px 0;
  width: 100%;
  height: auto;
  line-height: 2;
  font-size: 16px;
  text-align: left;
  color: var(--color-dark-gray);
  border-radius: 5px;
  flex: 1;

  p {
    text-indent: 40px;
  }
}
</style>
