import axios, { AxiosInstance, AxiosResponse } from 'axios'

const BASE_URL = 'https://gitee.com'
const TIME_OUT = 10 * 1000
const HEADERS = {
  'Content-Type': 'application/json'
}

// 创建 axios 实例
const createAxios = ({ baseURL = BASE_URL, timeout = TIME_OUT, headers = {} }) => {
  const instance = axios.create({ baseURL, timeout, headers })
  // addRequestInterceptor(instance)
  // addResponseInterceptor(instance)
  return instance
}

// 创建默认 instance
const axiosInstance = createAxios({
  baseURL: BASE_URL,
  headers: HEADERS
})

// 添加请求拦截器
// const addRequestInterceptor = (instance: AxiosInstance) => {
//   instance.interceptors.request.use(
//     function (config) {
//       // TODO：此处可设置请求拦截器属性，比如：config.headers
//       return config
//     },
//     function (error) {
//       // TODO：此处可设置对请求错误的操作
//       console.log('[网络请求] 请求拦截器 失败 --- ', error)
//       return Promise.reject(error)
//     }
//   )
// }

// 添加响应拦截器
// const addResponseInterceptor = (instances: AxiosInstance) => {
//   instances.interceptors.response.use(
//     function (response) {
//       // TODO：此处可设置响应拦截器属性，比如：code 校验，数据处理等
//       return response
//     },
//     function (error) {
//       // TODO：此处可设置对相应错误的操作，比如响应码的异常处理
//       console.log('[网络请求] 响应拦截器 失败 --- ', error)
//       return Promise.reject(error)
//     }
//   )
// }

// GET 请求
const get = ({
  instance,
  url,
  params = {},
  headers = {}
}: {
  instance?: AxiosInstance
  url: string
  params?: any
  headers?: any
}) => {
  return new Promise(
    (resolve: (value: AxiosResponse) => void, reject: (value: AxiosResponse) => void) => {
      if (instance) {
        instance
          .get(url, { params, headers })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      } else {
        axios
          .get(url, { params, headers })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      }
    }
  )
}

// POST 请求
const post = ({
  instance,
  url,
  params = {},
  headers = {}
}: {
  instance?: AxiosInstance
  url: string
  params?: any
  headers?: any
}) => {
  return new Promise(
    (resolve: (value: AxiosResponse) => void, reject: (value: AxiosResponse) => void) => {
      if (instance) {
        instance
          .post(url, { params, headers })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      } else {
        axios
          .post(url, { params, headers })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      }
    }
  )
}

export { createAxios, axiosInstance, get, post }
