import HomeView from '@/views/HomeView.vue'
import ProductView from '@/views/ProductView.vue'
import SupportView from '@/views/SupportView.vue'
import AboutView from '@/views/AboutView.vue'

export default [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/product',
    name: 'ProductView',
    component: ProductView
  },
  {
    path: '/support',
    name: 'SupportView',
    component: SupportView
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  }
]
