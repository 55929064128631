import { defineStore } from 'pinia'
import { getAllProductsUtil } from '@/components/common/Utils/commonUtil'
import type { AppInfo, CommonState } from '@/types/stores/common'

export enum RouteType {
  root = -1,
  home = 0,
  product = 1,
  support = 2,
  about = 3,
  product_station = 100
}

export const useCommonStore = defineStore('common', {
  state: (): CommonState => {
    return <CommonState>{
      curRouteType: RouteType.home,
      apps: []
    }
  },
  getters: {},
  actions: {
    // 获取所有产品信息
    getAllProducts() {
      getAllProductsUtil((res) => {
        if (res) {
          this.apps = res as AppInfo[]
        }
      })
    }
  }
})
