import { get } from '@/apis'

const urls = {
  getAllProducts: 'products.json'
}

// 获取所有产品配置信息
const requestGetAllProducts = () => {
  return get({
    instance: undefined,
    url: urls.getAllProducts
  })
}

export { requestGetAllProducts }
