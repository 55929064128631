<script setup lang="ts">
import { useCommonStore } from '@/stores/modules/common'
import { useRouter } from 'vue-router'
import type { AppInfo } from '@/types/stores/common'
import { getAssetsFile } from '@/utils/common'

const commonStore = useCommonStore()
const router = useRouter()

const clickProductItemEvent = (item: AppInfo) => {
  if (item.path.length && item.state === 'complete') {
    const routeUrl = router.resolve({
      path: item.path
    })
    window.open(routeUrl.href, '_self')
  }
}
</script>

<template>
  <div class="product-wrapper">
    <div class="product-list">
      <div
        v-for="item in commonStore.apps"
        :key="item.path"
        class="product-item"
        @click.stop="clickProductItemEvent(item)"
      >
        <img class="item-icon" :src="`${getAssetsFile(item.icon)}`" alt="" />
        <div class="item-info">
          <span class="info-name">
            {{ item.name }}
            <span v-show="item.state !== 'complete'" class="info-state">（{{ item.state_desc }}）</span>
          </span>
          <span class="info-desc">{{ item.desc }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.product-list {
  display: flex;
  // 是 align-content 和 justify-content 的合并写法
  place-content: flex-start space-between;
  align-items: start;
  width: 100%;
  flex-wrap: wrap;
  flex: 1;
}

.product-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: calc(50% - 10px);
  height: 80px;
  background-color: var(--bg-white);
  border-radius: 5px;
  cursor: pointer;

  .item-icon {
    margin-left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .item-info {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 20px;

    .info-name {
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      color: var(--color-dark-gray);
    }

    .info-state {
      font-size: 12px;
      color: var(--color-light-gray);
    }

    .info-desc {
      margin-top: 10px;
      width: 100%;
      font-size: 14px;
      color: var(--color-light-gray);
    }
  }
}
</style>
