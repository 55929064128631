<script setup lang="ts">
import { RouteType, useCommonStore } from '@/stores/modules/common'
import { useRouter } from 'vue-router'
import type { RouteInfo } from '@/types/stores/common'
import { getAllRouteInfos } from '@/components/common/Utils/commonUtil'
import { onMounted } from 'vue'

const commonStore = useCommonStore()
const router = useRouter()
let routeList: RouteInfo[] = []

onMounted(() => {
  routeList = getAllRouteInfos().filter((item) =>
    [RouteType.product, RouteType.support, RouteType.about, RouteType.product_station].includes(
      item.type
    )
  )
})

// 路由跳转
const clickGoToEvent = (type: RouteType) => {
  const findItem = routeList.find((item) => item.type === type)
  if (findItem) {
    commonStore.curRouteType = findItem.type

    const routeUrl = router.resolve({
      path: findItem.path
    })
    window.open(routeUrl.href, '_self')
  }
}
</script>

<template>
  <div class="footer-wrapper">
    <div class="footer-top">
      <div class="top-category">
        <span class="category-title text" @click.stop="clickGoToEvent(RouteType.product)"
          >产品中心
        </span>
        <ul>
          <li class="category-item text" @click.stop="clickGoToEvent(RouteType.product_station)">
            苜蓿小站
          </li>
          <li class="category-item text">苜蓿百宝箱（开发中）</li>
        </ul>
      </div>
      <div class="top-category">
        <span class="category-title text" @click.stop="clickGoToEvent(RouteType.support)"
          >联系我们
        </span>
        <ul>
          <li class="category-item text">jijiucheng777@163.com</li>
          <li class="category-item text" @click.stop="clickGoToEvent(RouteType.about)">关于我们</li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="bottom-PSB">
        <img src="@/assets/icons/icon_PSB.png" alt="" />
        <a href="https://beian.mps.gov.cn/#/query/webSearch">皖公网安备34011102003560号</a>
      </div>
      <a href="https://beian.miit.gov.cn">皖ICP备2024047637号-1</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-wrapper {
  padding: 20px 20% 10px;
  width: 100%;
  height: 150px;
  color: var(--color-white-gray);
  background-color: var(--bg-black);
}

.footer-top {
  display: flex;
  height: 90px;

  .text {
    margin: 5px 0;
    white-space: nowrap;
  }

  .top-category {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;

    .category-title {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    .category-item {
      font-size: 13px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 20px;
  font-size: 14px;

  a {
    color: var(--color-white-gray);
    text-decoration: none;
  }

  .bottom-PSB {
    display: flex;
    align-items: center;
    margin-right: 20px;

    img {
      margin-right: 5px;
      width: 15px;
    }
  }
}
</style>
