<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'
import { RouteType, useCommonStore } from '@/stores/modules/common'
import { useRouter } from 'vue-router'
import type { RouteInfo } from '@/types/stores/common'
import { getAllRouteInfos } from '@/components/common/Utils/commonUtil'

const commonStore = useCommonStore()
const router = useRouter()
const rootRouteInfo = ref<RouteInfo>({
  title: '',
  path: '/',
  type: RouteType.root
})
const routeList: Ref<RouteInfo[]> = ref([])

onMounted(() => {
  const findRoot = getAllRouteInfos().find((item) => item.type === RouteType.home)
  if (findRoot) {
    rootRouteInfo.value = findRoot
  }
  routeList.value = getAllRouteInfos().filter((item) =>
    [RouteType.home, RouteType.product, RouteType.support, RouteType.about].includes(item.type)
  )
})

// eslint-disable-next-line no-undef
const clickItemEvent = (item: RouteInfo) => {
  if (item) {
    commonStore.curRouteType = item.type

    const routeUrl = router.resolve({
      path: item.path
    })
    window.open(routeUrl.href, '_self')
  }
}
</script>

<template>
  <div class="header-wrapper">
    <div class="header-content">
      <img
        class="header-item logo"
        src="../../assets/icons/icon_logo.png"
        alt="苜蓿小站"
        @click.stop="clickItemEvent(rootRouteInfo)"
      />
      <template v-for="(item, index) in routeList" :key="index">
        <span
          class="header-item button"
          :class="{ active: commonStore.curRouteType === item.type }"
          @click.stop="clickItemEvent(item)"
          >{{ item.title }}
        </span>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 50px;
  background-color: var(--bg-black);
}

.header-content {
  display: flex;
  align-items: center;
  margin: 0 20%;
  height: 100%;
}

.header-item {
  margin-right: 100px;
  cursor: pointer;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.button {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  height: 100%;
  font-size: 15px;
  white-space: nowrap;
  color: var(--color-white-gray);
  font-weight: 600;
  border-bottom: 3px solid transparent;

  &.active {
    border-bottom: 3px solid var(--color-theme);
  }
}
</style>
