<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useCommonStore } from '@/stores/modules/common'
import type { AppInfo } from '@/types/stores/common'
import { getAssetsFile } from '@/utils/common'

const commonStore = useCommonStore()
const stationApp = ref<AppInfo>()

watch(
  () => commonStore.apps,
  () => {
    const findItem = commonStore.apps.find((item) => item.product === 'station')
    if (findItem) {
      stationApp.value = findItem
      console.log('当前产品 --- ', stationApp.value)
    }
  }, { deep: true }
)

onMounted(() => {
  commonStore.getAllProducts()
})
</script>

<template>
  <div class="station-wrapper">
    <h2 class="app-name">{{ stationApp?.name }}</h2>
    <template v-for="(item, index) in stationApp?.intros" :key="index">
      <div class="intro-item" :class="{ reverse: item.type === 'right' }">
        <img class="item-screen-shot" :src="`${getAssetsFile(item.image)}`" :alt="stationApp?.name" />
        <span class="item-intro">{{ item.content }}</span>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.station-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.app-name {
  margin: 20px 0;
  font-size: 30px;
  font-weight: bold;
}

.intro-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 15px;
  width: 100%;
  height: 700px;
  background-color: var(--bg-white);
  border-radius: 5px;

  .item-screen-shot {
    height: 100%;
  }

  .item-intro {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    font-size: 18px;
    text-indent: 40px;
    background-color: var(--bg-white);
    line-height: 2;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}
</style>
