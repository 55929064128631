<script setup lang="ts"></script>

<template>
  <div class="support-wrapper">
    <h3 class="support-title">官方联系方式：</h3>
    <span class="support-content">
      <p>
        邮箱：
        <span class="support-email">jijiucheng777@163.com</span>
      </p>
      <p>如果你有任何想咨询的问题，可以通过发邮件与我们联系。</p>
    </span>
  </div>
</template>

<style scoped lang="scss">
.support-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
}

.support-title {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  font-size: 20px;
  text-align: left;
  line-height: 60px;
  font-weight: 600;
}

.support-content {
  padding: 10px;
  margin: 20px 0;
  width: 100%;
  height: auto;
  line-height: 2;
  font-size: 16px;
  text-align: left;
  color: var(--color-dark-gray);
  border-radius: 5px;
  flex: 1;

  .support-email {
    font-weight: 600;
    color: var(--color-theme);
  }

  p {
    text-indent: 40px;
  }
}
</style>
