<script setup lang="ts">
import { onMounted } from 'vue'
import { useCommonStore } from '@/stores/modules/common'
import HeaderView from '@/components/common/HeaderView.vue'
import FooterView from '@/components/common/FooterView.vue'

const commonStore = useCommonStore()

onMounted(() => {
  commonStore.getAllProducts()
})
</script>

<template>
  <HeaderView />
  <div class="app-content-wrapper">
    <RouterView />
  </div>
  <FooterView />
</template>

<style scoped lang="scss">
.app-content-wrapper {
  flex: 1;
  padding: 50px 20% 0;
  width: 100%;
  background-color: var(--bg-light-gray);
}
</style>
