import { requestGetAllProducts } from '@/apis/modules/common'
import type { RouteInfo } from '@/types/stores/common'
import { RouteType } from '@/stores/modules/common'

type Callback = (params: unknown) => void

export const getAllRouteInfos = (): RouteInfo[] => {
  return [
    {
      title: '',
      path: '/',
      type: RouteType.root
    },
    {
      title: '首页',
      path: '/home',
      type: RouteType.home
    },
    {
      title: '产品中心',
      path: '/product',
      type: RouteType.product
    },
    {
      title: '用户支持',
      path: '/support',
      type: RouteType.support
    },
    {
      title: '关于我们',
      path: '/about',
      type: RouteType.about
    },
    {
      title: '苜蓿小站',
      path: '/product_station',
      type: RouteType.product_station
    }
  ]
}

export const getAllProductsUtil = (callBack: Callback) => {
  requestGetAllProducts()
    .then((res) => {
      console.log('获取所有产品信息 成功 --- ', res)
      callBack(res.data)
    })
    .catch((err) => {
      console.log('获取所有产品信息 失败 --- ', err)
      callBack(null)
    })
}
